<template>
    <div>
      <v-btn text class="mb-2" @click="goBack">
        <v-icon left>
          mdi-arrow-left
        </v-icon>
        Regresar a reportes
      </v-btn>
      <div class="card card-custom gutter-b">
        <!-- begin::Page header and title -->
        <div class="card-header flex-nowrap border-0 pt-6 pb-0">
          <div class="card-title">
            <div class="card-label">
              <h3>
                Generación de reporte
                <span class="d-block text-muted pt-2 font-size-sm"
                  >Reporte paramatrizado para información de estudiantes con fotografía</span
                >
              </h3>
              <p class="ma-0 pa-0 text-body-1">
                <strong
                  >Antes de poder generar el reporte, es nesario que seleccione
                  al menos 3 parametros.</strong
                >
              </p>
              <p class="ma-0 pa-0 text-body-1">
                - La lista de <strong>grupos académicos</strong> se cargará cuando
                haya elegido un grado.
              </p>
              <p class="ma-0 pa-0 text-body-1">
                - La lista de
                <strong>grupos de especialidad</strong> se cargará cuando haya
                seleccionado un grado mayor a 9°.
              </p>
              <p class="ma-0 pa-0 text-body-1">
                - No se puede seleccionar un grupo académico y un grupo de
                especialidad al mismo tiempo.
              </p>
            </div>
          </div>
        </div>
        <!-- end::Page header and title -->
  
        <div class="card-body px-1 px-sm-5">
          <v-container>
            <v-row>
              <v-col cols="12">
                <!-- begin::filters for report generation-->
                <v-card class="elevation-2 mb-5">
                  <p class="text-h5 ma-0 pt-4 pl-4 font-weight-medium">
                    Parámetros
                  </p>
                  <v-divider></v-divider>
                  <!-- begin::Filters combos -->
                  <v-container class="pa-4">
                    <v-row class="align-items-center mb-5">
                      <!-- begin::select academic level filter-->
                      <v-col cols="12" sm="6" lg="4" xl="3" class="my-2 my-md-0">
                        <div class="d-flex align-items-center">
                          <!-- begin:grade combo -->
                          <v-select
                            label="Grado"
                            outlined
                            :loading="isSectionGroupLoading"
                            hide-details
                            :items="grades"
                            item-text="name"
                            item-value="id"
                            v-model="students.filters.grade_id"
                            @click="resetFilters()"
                            @change="loadAcademicGroups(), loadTechnicalGroups()"
                          >
                            <!-- begin::selected grade item -->
                            <template v-slot:selection="{ item, index }">
                              <v-chip color="primary" small v-if="index === 0">
                                <span>{{ item.name }}</span>
                              </v-chip>
                            </template>
                            <!-- end::selected grade item -->
                          </v-select>
                          <!-- end:grade combo -->
                        </div>
                      </v-col>
                      <!-- end::select academic level filter-->
  
                      <!-- begin::select section/group filter-->
                      <v-col cols="12" sm="6" lg="4" xl="3" class="my-2 my-md-0">
                        <div class="d-flex align-items-center">
                          <!-- begin:: academicGroup combo -->
                          <v-select
                            outlined
                            hide-details
                            clearable
                            item-text="grade_section_group_formatted"
                            item-value="id"
                            v-model="students.filters.grade_section_group_id"
                            :loading="isSectionGroupLoading"
                            :disabled="
                              !students.filters.grade_id ||
                                !!students.filters.grade_speciality_group_id ||
                                isSectionGroupLoading
                            "
                            :items="academicGroups"
                            :label="
                              isSectionGroupLoading
                                ? 'Cargando grupos académicos...'
                                : 'Grupo académico'
                            "
                          >
                            <!-- begin::selected academicGroup item -->
                            <template v-slot:selection="{ item, index }">
                              <v-chip color="primary" small v-if="index === 0">
                                <span>{{
                                  item.grade_section_group_formatted
                                }}</span>
                              </v-chip>
                            </template>
                            <!-- end::selected academicGroup item -->
                          </v-select>
                          <!-- end:: academicGroup combo -->
                        </div>
                      </v-col>
                      <!-- end::select section/group filter-->
  
                      <!-- begin::select section/group filter-->
                      <v-col cols="12" sm="6" lg="4" xl="3" class="my-2 my-md-0">
                        <!-- begin:: technicalGroup combo -->
                        <div class="d-flex align-items-center">
                          <v-select
                            :label="
                              isSectionGroupLoading
                                ? 'Cargando grupos técnicos...'
                                : 'Grupo técnico'
                            "
                            :loading="isSectionGroupLoading"
                            :disabled="isSectionGroupLoading || !canSelectSpecialityGroups"
                            outlined
                            hide-details
                            clearable
                            :items="technicalGroups"
                            item-text="grade_speciality_group_formatted"
                            item-value="id"
                            v-model="students.filters.grade_speciality_group_id"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip color="primary" small v-if="index === 0">
                                <span>{{
                                  item.grade_speciality_group_formatted
                                }}</span>
                              </v-chip>
                            </template>
                          </v-select>
                        </div>
                        <!-- end:: technicalGroup combo -->
                      </v-col>
                      <!-- end::select section/group filter-->
                    </v-row>
                  </v-container>
                  <!-- end::Filters combos -->
                </v-card>
                <!-- end::filters for report generation-->
  
                <!-- begin::cards with generator button for each report -->
                <template>
                  <v-row>
                    <!-- begin::excel with students scores col-container-->
                    <v-col cols="12" sm="8" md="8" lg="6" xl="4">
                      <!-- begin::excel with students scores-->
                      <v-card outlined :disabled="!canGenerateReport">
                        <!-- begin::card body -->
                        <v-list-item three-line>
                          <v-list-item-content>
                            <div class="text-overline mb-4">
                              estudiantes
                            </div>
                            <v-list-item-title class="text-h5 mb-1">
                              Listado de estudiantes
                            </v-list-item-title>
<!--                             <v-list-item-subtitle tag="p"
                              >Estudiantes agrupados por
                              <strong
                                >grupo
                                {{
                                  students.filters.stage_id == 8
                                    ? "técnico"
                                    : "académico"
                                }}</strong
                              >
                              en una etapa.</v-list-item-subtitle
                            > -->
                            <v-list-item-subtitle>Estudiantes con fotografía.</v-list-item-subtitle>
                          </v-list-item-content>
  
                          <v-list-item-avatar
                            class="rounded"
                            tile
                            icon
                            size="80"
                            color="red accent-4"
                          >
                            <v-icon x-large color="white" class="ml-n1"
                              >mdi-file-pdf-box</v-icon
                            ></v-list-item-avatar
                          >
                        </v-list-item>
                        <!-- end::card body -->
  
                        <!-- begin:: card button for report generation -->
                        <v-card-actions>
                          <v-btn
                            outlined
                            rounded
                            text
                            target="_blank"
                            @click="generateDocument(students.filters)"
                            :loading="isLoadingLink"
                          >
                            Generar reporte
                          </v-btn>
                        </v-card-actions>
                        <!-- end:: card button for report generation -->
                      </v-card>
                    </v-col>
                    <!-- end::excel with students scores col-container-->
                  </v-row>
                </template>
                <!-- end::cards with generator button for each report -->
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import {
    GET_CURRENT_PAGE_ACTIONS,
    PURGE_CURRENT_PAGE_ACTIONS,
  } from "@/core/services/store/role.module";
  import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
  import { mapGetters } from "vuex";
  import gradeRepository from "@/repositories/gradeRepository";
  import academicGroupRepository from "@/repositories/academicGroupRepository";
  import technicalGroupRepository from "@/repositories/technicalGroupRepository";
  import ApiService from "@/core/services/api.service";

  export default {
    name: "AcademicPerformanceReport",
    title: "Reporte de estudiantes | GE ITR",
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Reportes", route: "reports" },
        {
          title: "Reporte de estudiante con fotografía",
          route: "student_report_with_photo",
        },
      ]);
      this.$store.dispatch(
        GET_CURRENT_PAGE_ACTIONS,
        "Reporte de estudiantes con fotografía"
      );
  
      this.loadGrades();
    },
    data() {
      return {
        students: {
          data: [],
          filters: {},
          isLoading: false,
        },
        technicalGroupSpeciality: {},
        isSectionGroupLoading: false,
        technicalExcel: [],
        isLoadingLink: false,
        academicExcel: [],
        grades: [],
        academicGroups: [],
        technicalGroups: [],
        TERCER_CICLO_ID: 1,
        BACHILLERATO_ID: 2,
      };
    },
    methods: {
      //Fetching all grade academic levels
      loadGrades() {
        gradeRepository
          .getAllGrades()
          .then(({ data }) => {
            this.grades = data;
          })
          .catch(() => {
            this.fireToast({
              icon: "error",
              title: "Hubo un error al intentar obtener los datos",
            });
          });
      },
  
      //Fetching academic groups depending on the id of the grade that's been selected
      loadAcademicGroups() {
        this.isSectionGroupLoading = true;
        academicGroupRepository
          .getAcademicGroupsByGrade(this.students.filters.grade_id)
          .then(({ data }) => {
            this.academicGroups = data;
          })
          .catch(() => {
            this.fireToast({
              icon: "error",
              title: "Hubo un error al intentar obtener los datos",
            });
          })
          .finally(() => {
            this.isSectionGroupLoading = false;
          });
      },
  
      //Fetching technical groups depending on the academicGroup id that's been selected
      loadTechnicalGroups() {
        technicalGroupRepository
          .getTechnicalGroupsByGrade(this.students.filters.grade_id)
          .then(({ data }) => {
            this.technicalGroups = data;
          })
          .catch(() => {
            this.fireToast({
              icon: "error",
              title: "Hubo un error al intentar obtener los datos",
            });
          })
      },
  
      isTercerCiclo(selectedGrade) {
        return selectedGrade.academic_level_id == 1;
      },
  
      resetFilters() {
        this.students.filters = {};
      },
      goBack() {
        this.$router.go(-1);
      },
      async generateDocument(filter) {
        this.isLoadingLink = true;
        let url;
        const userEncrypted = await this.encryptId(this.currentUserPersonalInfo.id_user);
        if (filter?.grade_speciality_group_id) {
          const identificator = await this.encryptId(filter.grade_speciality_group_id);
          url =  `https://reportsge.ricaldone.edu.sv/public/api/students/photos/grade-speciality-group/${identificator}/user/${userEncrypted}`;
        } else {
         const identificator = await this.encryptId(filter.grade_section_group_id);
          url = `https://reportsge.ricaldone.edu.sv/public/api/students/photos/grade-section-group/${identificator}/user/${userEncrypted}`;
        }
        window.open(url, "_blank");
        this.isLoadingLink = false;
        return;
      },
      async encryptId(id) {
        try {
            const { data: encryptedId } = await ApiService.get(
            `${this.reportsApiUrl}/version/u/${id}`
            );
            return encryptedId;
        } catch (error) {
            this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar generar la lista",
            });
            return;
        }
    },
    },
    computed: {
      ...mapGetters(["currentUserPersonalInfo"]),
      reportsApiUrl() {
        let baseURL;
        if (process.env.REPORTS_APP_ENV === "production") {
            baseURL = process.env.REPORTS_API_URL;
        } else if (process.env.REPORTS_APP_ENV === "development") {
            baseURL = process.env.REPORTS_DEV_API_URL;
        } else {
            baseURL = process.env.REPORTS_LOCAL_API_URL;
        }
        return baseURL;
        },
  
      isTechnicalGroupSelected() {
        return !!this.students.filters?.grade_speciality_group_id;
      },
  
      academicLevelId() {
        if ("grade_id" in this.students.filters) {
          const academicLevel = this.grades.find(
            (item) => item.id == this.students.filters.grade_id
          );
          return academicLevel.academic_level.id;
        }
      },
  
      filteredData() {
        return this.filterData(this.students.filters, this.students.data);
      },

      canSelectSpecialityGroups() {
        return this.students.filters.grade_id > 3;
    },
  
      canGenerateReport() {
        return this.students.filters?.grade_section_group_id != null || this.students.filters?.grade_speciality_group_id != null
      },
      ...mapGetters(["currentPageActions"]),
    },
    beforeDestroy() {
      this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
    },
  };
  </script>
  